import React, { useRef, useState, useEffect } from 'react';
import {
  CreateOptions,
  ResearchOptions,
  BookListingTools,
  LearningTools,
  GeneralTools,
} from './CreateData';

import { Link } from 'react-router-dom';
import { EDropdown } from './index';
import './create.scss';

interface ICreateProps {
  show: boolean;
  transparentNav?: boolean;
  toggleDropDown: (type: EDropdown) => void;
  isMobile?: boolean;
  setActiveDropDown: (state: any) => void;
}

const Create: React.FC<ICreateProps> = ({
  show,
  transparentNav,
  toggleDropDown,
  isMobile,
  setActiveDropDown,
}) => {
  const divRef = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    setActiveDropDown({
      create: true,
      help: false,
    });
    // toggleDropDown(EDropdown.help);
    // console.log("handling click");
  };

  const handleClose = () => {
    // setIsOpen(false);
    // toggleDropDown(EDropdown.help);
    // console.log("closed");
    setActiveDropDown({
      create: false,
      help: false,
    });
  };

  // const handleClickOutside = (event: MouseEvent) => {
  //   if (divRef.current && !divRef.current.contains(event.target as Node)) {
  //     setActiveDropDown({
  //       create: false,
  //       help: false,
  //     });
  //   }
  // };

  // useEffect(() => {
  //   if (show) {
  //     document.addEventListener("mousedown", handleClickOutside);
  //   } else {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   }

  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [show]);

  return (
    <div
      ref={divRef}
      style={{
        position: 'relative',
        color: 'black',
        margin: '0 0.5rem',
        // marginTop: '0.1rem',
        display: 'inline-block',
        textAlign: 'left',
      }}>
      <button
        onClick={handleClick}
        onMouseEnter={handleClick}
        onMouseLeave={handleClose}
        style={{
          border: '1px solid',
          display: 'flex',
          padding: '0.5rem 1rem',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          borderRadius: '30px',
          backgroundColor: '#7936ec',
        }}>
        <span
          style={{
            fontWeight: 500,
            color: transparentNav ? 'white' : 'white',
          }}>
          Start
        </span>
      </button>
      <div
        onMouseEnter={() => {
          setActiveDropDown({
            create: true,
            help: false,
          });
        }}
        style={{
          position: 'absolute',
          top: '40px',
          height: '1.25rem',
          width: '100%',
        }}></div>
      {show && (
        <div
          style={{
            border: '1px solid #ccc',
            transform: isMobile ? 'translateX(-50%)' : 'translateX(-70%)',
            position: 'absolute',
            left: 0,
            marginTop: '0.5rem',
            maxHeight: '90vh',
            overflow: 'auto',
            width: '94vw',
            maxWidth: '1250px',
            borderRadius: '0.75rem',
            boxShadow: '0 10px 15px rgba(0, 0, 0, 0.1)',
            backgroundColor: 'white',
            padding: '20px',
          }}
          onMouseLeave={handleClose}>
          <div
            style={{
              padding: '0.25rem 0.75rem',
            }}
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu">
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr',
                width: '100%',
              }}>
              <div className="">
                <span
                  style={{
                    color: 'black',
                    paddingBottom: '20px',
                    marginTop: '1rem',
                    fontWeight: 'bold',
                  }}>
                  Research
                </span>
                <div
                  style={{
                    width: '100%',
                    marginTop: '10px',
                    display: 'grid',
                    gridTemplateColumns: 'repeat(5, 1fr)',
                    gap: '10px',
                  }}>
                  {ResearchOptions.map((option, index) => (
                    <LinkItem key={index} option={option} />
                  ))}
                </div>
              </div>
              <hr className="my-3 mt-4 " />
              <div>
                <span
                  style={{
                    color: 'black',
                    marginBottom: '0.25rem',
                    marginTop: '0.25rem',
                    fontWeight: 'bold',
                  }}>
                  Create
                </span>
                <div
                  style={{
                    width: '100%',
                    display: 'grid',
                    gridTemplateColumns: 'repeat(5, 1fr)',
                    marginTop: '10px',

                    gap: '10px',
                  }}>
                  {CreateOptions.map((option, index) => (
                    <LinkItem key={index} option={option} />
                  ))}
                </div>
              </div>

              <hr className="my-3 mt-4 " />
              <div>
                <span
                  style={{
                    color: 'black',
                    marginBottom: '0.25rem',
                    marginTop: '1rem',
                    fontWeight: 'bold',
                  }}>
                  Book Listing
                </span>
                <div
                  style={{
                    width: '100%',
                    display: 'grid',
                    gridTemplateColumns: 'repeat(5, 1fr)',
                    marginTop: '10px',

                    gap: '10px',
                  }}>
                  {BookListingTools.map((option, index) => (
                    <LinkItem key={index} option={option} />
                  ))}
                </div>
              </div>
              <hr className="my-3 mt-4 " />
              <div>
                <span
                  style={{
                    color: 'black',
                    marginBottom: '0.25rem',
                    marginTop: '1rem',
                    fontWeight: 'bold',
                  }}>
                  General
                </span>
                <div
                  style={{
                    width: '100%',
                    display: 'grid',
                    marginTop: '10px',

                    gridTemplateColumns: 'repeat(5, 1fr)',
                    gap: '10px',
                  }}>
                  {GeneralTools.map((option, index) => (
                    <LinkItem key={index} option={option} />
                  ))}
                </div>
              </div>
              <hr className="my-3 mt-4 " />
              <div>
                <span
                  style={{
                    color: 'black',
                    marginBottom: '0.25rem',
                    marginTop: '1rem',
                    fontWeight: 'bold',
                  }}>
                  Education
                </span>
                <div
                  style={{
                    width: '100%',
                    display: 'grid',
                    marginTop: '10px',
                    gridTemplateColumns: 'repeat(5, 1fr)',
                    gap: '10px',
                  }}>
                  {LearningTools.map((option, index) => (
                    <LinkItem key={index} option={option} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Create;

function LinkItem({ option }) {
  return (
    <Link
      to={option.link}
      target="__blank"
      style={{
        display: 'flex',
        alignItems: 'center',
        borderRadius: '16px',
        padding: '10px',
        fontSize: '0.875rem',
        color: 'gray',
        height: '60px',
        border: '1px solid #ccc',
        textDecoration: 'none',
        backgroundColor: 'transparent',
      }}
      onMouseEnter={(e) => {
        e.currentTarget.style.backgroundColor = '#e6e6fa';
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.backgroundColor = 'transparent';
        e.currentTarget.style.color = 'inherit';
      }}
      role="menuitem">
      <option.Icon
        style={{
          marginRight: '1rem',
          fontSize: '20px',
          color: '#7936ec',
        }}
      />
      <span style={{ fontWeight: '500', color: 'black' }}>{option.name}</span>
    </Link>
  );
}
