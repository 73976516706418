import React, { useState, useCallback } from 'react';
import { Form, InputGroup, Spinner, ListGroup } from 'react-bootstrap';

import debounce from 'lodash/debounce';

import ToolSearchData, { SectionItem } from './searchData';
import { MdSearch } from 'react-icons/md';

// import { getRequest } from "@/api/interface";
// import {
//   Search,
//   Tools,
//   ChatDots,
//   FileEarmarkText,
// } from 'react-bootstrap-icons';

export const blogsdata: BlogPost[] = [
  {
    title: 'Where To Self-publish A Book',
    link: '/blog/where-to-self-publish-a-book',
    date: '2023-09-28T22:44:35.028Z',
    thumbnail:
      'https://booksbytitans-bucket.sgp1.digitaloceanspaces.com/selfpublishingtitans-booksbytitans-files/9e2dcfe463d85c72e012fdd11168929e.png',
    id: '651601cca2a08d001b89b20d',
  },
  {
    title: 'A Guide to Self-Publishing a Coloring Book',
    link: '/blog/a-guide-to-self-publishing-a-coloring-book',
    date: '2023-09-27T23:17:30.843Z',
    thumbnail:
      'https://booksbytitans-bucket.sgp1.digitaloceanspaces.com/selfpublishingtitans-booksbytitans-files/3fcb1c1afcc510e53954f57b182286e2.png',
    id: '65149afaa2a08d001b89b201',
  },
  {
    title: 'Self-Publishing A Coloring Book',
    link: '/blog/self-publishing-a-coloring-book',
    date: '2023-09-22T19:47:08.029Z',
    thumbnail:
      'https://booksbytitans-bucket.sgp1.digitaloceanspaces.com/selfpublishingtitans-booksbytitans-files/bc5908044dad096d86a9e4676f847299.png',
    id: '650def37a2a08d001b89b1fe',
  },
  {
    title: 'How to Publish Your Book: Navigating the Literary Journey',
    link: '/blog/how-to-publish-your-book-navigating-the-literary-journey',
    date: '2023-09-21T15:17:05.998Z',
    thumbnail:
      'https://booksbytitans-bucket.sgp1.digitaloceanspaces.com/selfpublishingtitans-booksbytitans-files/7a1ef211d30ba2ff2789ccf5b50ba12d.png',
    id: '650c5e6ba2a08d001b89b1fb',
  },
  {
    title: 'Self-Publishing with Amazon',
    link: '/blog/Yo',
    date: '2023-09-20T19:45:30.216Z',
    thumbnail:
      'https://booksbytitans-bucket.sgp1.digitaloceanspaces.com/selfpublishingtitans-booksbytitans-files/e50c0d826621f345fcd9e23802486190.png',
    id: '650a5213a2a08d001b89b1ec',
  },
  {
    title: 'How To Self-Publish A Book',
    link: '/blog/how-to-self-publish-a-book',
    date: '2023-09-19T20:37:44.104Z',
    thumbnail:
      'https://booksbytitans-bucket.sgp1.digitaloceanspaces.com/selfpublishingtitans-booksbytitans-files/b16580a17343a3bda992d3ccf45159ff.png',
    id: '650a0691a2a08d001b89b1e8',
  },
  {
    title: 'Is Kindle Direct Publishing Still Worth It?',
    link: '/blog/is-kindle-direct-publishing-still-worth-it',
    date: '2023-09-13T15:33:38.825Z',
    thumbnail:
      'https://booksbytitans-bucket.sgp1.digitaloceanspaces.com/selfpublishingtitans-booksbytitans-files/64d8a74f0a644911d107761ed9384c4d.png',
    id: '6501d62aa2a08d001b89b1e6',
  },
  {
    title: 'Is Amazon KDP Worth It?',
    link: '/blog/is-amazon-kdp-worth-it',
    date: '2023-09-13T15:02:01.966Z',
    thumbnail:
      'https://booksbytitans-bucket.sgp1.digitaloceanspaces.com/selfpublishingtitans-booksbytitans-files/5c027635d55af9a91903ad57f7a87c7b.png',
    id: '6501cee5a2a08d001b89b1e3',
  },
  {
    title: "Cleo & Corvin Van Stone Meeting KDP'ers From Around The World",
    link: '/blog/cleo-and-corvin-van-stone-meeting-kdp-ers-from-around-the-world',
    date: '2023-08-24T01:28:20.165Z',
    thumbnail:
      'https://booksbytitans-bucket.sgp1.digitaloceanspaces.com/selfpublishingtitans-booksbytitans-files/a8877f1e486337e018e5bfb340b67ad1.jpg',
    id: '64e6b1b04c0e78001b318076',
  },
  {
    title: 'Self Publishing Titans KDP Masterclass Reviews',
    link: '/blog/self-publishing-titans-kdp-masterclass-reviews',
    date: '2023-03-22T10:30:11.645Z',
    thumbnail:
      'https://booksbytitans-bucket.sgp1.digitaloceanspaces.com/selfpublishingtitans-booksbytitans-files/0524a1e61f3da99941a6c5e57834384c.jpg',
    id: '641ad73c4c0e78001b31806a',
  },
  {
    title: 'Creating a KDP account on Amazon',
    link: '/blog/creating-a-kdp-account-on-amazon',
    date: '2022-12-12T16:15:58.542Z',
    thumbnail:
      'https://booksbytitans-bucket.sgp1.digitaloceanspaces.com/selfpublishingtitans-booksbytitans-files/e95b05ef9c221f39f9fb0fb9b709bb0b.png',
    id: '639753b84c0e78001b318068',
  },
  {
    title: 'How to Set up a KDP Account on Amazon',
    link: '/blog/how-to-set-up-a-kdp-account-on-amazon',
    date: '2022-12-11T19:05:47.940Z',
    thumbnail:
      'https://booksbytitans-bucket.sgp1.digitaloceanspaces.com/selfpublishingtitans-booksbytitans-files/314600bb4384a074886fe91b09b92491.png',
    id: '63962a094c0e78001b318065',
  },
  {
    title: 'How to Make a Calendar with InDesign',
    link: '/blog/how-to-make-a-calendar-in-in-design',
    date: '2022-12-11T17:52:42.592Z',
    thumbnail:
      'https://booksbytitans-bucket.sgp1.digitaloceanspaces.com/selfpublishingtitans-booksbytitans-files/329e6a33a772411d16f6591324ff28b7.png',
    id: '639618dd4c0e78001b318060',
  },
  {
    title: 'How to Create a Nice Book Cover',
    link: '/blog/how-to-create-a-nice-book-cover',
    date: '2022-12-10T20:56:20.853Z',
    thumbnail:
      'https://booksbytitans-bucket.sgp1.digitaloceanspaces.com/selfpublishingtitans-booksbytitans-files/bdb5bf899f96d3b14c8535af4e3b3d07.png',
    id: '6394f25d4c0e78001b31805d',
  },
  {
    title: '10 Basic Tips for Amazon Kindle Direct Publishing',
    link: '/blog/10-basic-tips-for-amazon-kindle-direct-publishing',
    date: '2022-12-10T18:37:28.048Z',
    thumbnail:
      'https://booksbytitans-bucket.sgp1.digitaloceanspaces.com/selfpublishingtitans-booksbytitans-files/dda1039abcb740379c13b0a25797deb6.png',
    id: '6394d1e24c0e78001b31805a',
  },
  {
    title: 'How to Write a Kids Story Books',
    link: '/blog/how-to-write-kids-story-books',
    date: '2022-12-10T17:08:31.296Z',
    thumbnail:
      'https://booksbytitans-bucket.sgp1.digitaloceanspaces.com/selfpublishingtitans-booksbytitans-files/209c4f014a90c2145c992d19fcbc280a.png',
    id: '6394bd0c4c0e78001b318057',
  },
  {
    title: 'How To Write a Poem',
    link: '/blog/how-to-write-a-poem',
    date: '2022-12-10T15:59:55.783Z',
    thumbnail:
      'https://booksbytitans-bucket.sgp1.digitaloceanspaces.com/selfpublishingtitans-booksbytitans-files/3818cd39f4f98177959745e3c5340a4d.png',
    id: '6394aceb4c0e78001b318054',
  },
  {
    title: 'How to Write a Memoir',
    link: '/blog/how-to-write-a-memoir',
    date: '2022-12-10T15:26:07.061Z',
    thumbnail:
      'https://booksbytitans-bucket.sgp1.digitaloceanspaces.com/selfpublishingtitans-booksbytitans-files/0a15bd6198ec8949e3783c1366a9fe20.png',
    id: '6394a50a4c0e78001b318051',
  },
  {
    title: 'How to Write a Good Book Description for your KDP Books',
    link: '/blog/how-to-write-a-good-book-description-for-your-kdp-books',
    date: '2022-12-10T15:09:25.829Z',
    thumbnail:
      'https://booksbytitans-bucket.sgp1.digitaloceanspaces.com/selfpublishingtitans-booksbytitans-files/8ce5f8534c12a2e5fb0a94b0ed03b3e3.png',
    id: '6394a1004c0e78001b31804c',
  },
  {
    title: 'How to Find the Best Niches for Amazon KDP',
    link: '/blog/how-to-find-the-best-niches-for-amazon-kdp',
    date: '2022-12-10T00:18:40.957Z',
    thumbnail:
      'https://booksbytitans-bucket.sgp1.digitaloceanspaces.com/selfpublishingtitans-booksbytitans-files/5f25e982617c39926f56c3790336e53e.png',
    id: '6393d05a4c0e78001b318047',
  },
  {
    title: 'When do you get Paid Royalties from Amazon KDP',
    link: '/blog/when-do-you-get-paid-royalties-from-amazon-kdp',
    date: '2022-12-09T20:38:46.297Z',
    thumbnail:
      'https://booksbytitans-bucket.sgp1.digitaloceanspaces.com/selfpublishingtitans-booksbytitans-files/dc642809defb81df4e06b73537826a89.png',
    id: '63939c9d4c0e78001b318042',
  },
  {
    title: 'How To Order a Proof or Author Copy of my KDP Book',
    link: '/blog/how-to-order-a-proof-or-author-copy-of-my-kdp-book',
    date: '2022-12-08T20:30:58.992Z',
    thumbnail:
      'https://booksbytitans-bucket.sgp1.digitaloceanspaces.com/selfpublishingtitans-booksbytitans-files/02f74d2ee08ee9e9db8884737f642f44.png',
    id: '639249804c0e78001b31803f',
  },
  {
    title: 'How to Update, Unpublish & Delete Books on your KDP Bookshelf',
    link: '/blog/how-to-update-unpublish-and-delete-books-on-your-kdp-bookshelf',
    date: '2022-12-07T19:20:25.128Z',
    thumbnail:
      'https://booksbytitans-bucket.sgp1.digitaloceanspaces.com/selfpublishingtitans-booksbytitans-files/1e625e0dd3331260e03552640f2b13e2.png',
    id: '6390e7694c0e78001b31803b',
  },
  {
    title: 'How To Link & Unlink Your KDP Books',
    link: '/blog/how-to-link-and-unlink-your-kdp-books',
    date: '2022-12-06T23:24:39.071Z',
    thumbnail:
      'https://booksbytitans-bucket.sgp1.digitaloceanspaces.com/selfpublishingtitans-booksbytitans-files/1f91869b8b2979af11179a2ea77eca1a.png',
    id: '638fcf344c0e78001b318038',
  },
  {
    title: 'KDP Barcodes',
    link: '/blog/barcodes',
    date: '2022-12-06T19:39:37.300Z',
    thumbnail:
      'https://booksbytitans-bucket.sgp1.digitaloceanspaces.com/selfpublishingtitans-booksbytitans-files/8fa533960ab63db0e71762f5801f1a1e.png',
    id: '638f99d74c0e78001b318034',
  },
  {
    title: 'A+ Content Guidelines',
    link: '/blog/a-content-guidelines',
    date: '2022-12-04T18:49:08.364Z',
    thumbnail:
      'https://booksbytitans-bucket.sgp1.digitaloceanspaces.com/selfpublishingtitans-booksbytitans-files/e46032d28ba70cffee2e669549573e81.png',
    id: '638ceaba4c0e78001b318032',
  },
  {
    title: 'How to Create A+ Content',
    link: '/blog/how-to-create-a-content',
    date: '2022-12-04T18:39:03.646Z',
    thumbnail:
      'https://booksbytitans-bucket.sgp1.digitaloceanspaces.com/selfpublishingtitans-booksbytitans-files/21bd47151ed0a01ccaa2bc83faa8cadb.png',
    id: '638ce1074c0e78001b31802f',
  },
  {
    title: 'How to Create an Author Page on Amazon Author Central',
    link: '/blog/how-to-create-an-author-page-on-amazon-author-central',
    date: '2022-12-04T17:39:59.248Z',
    thumbnail:
      'https://booksbytitans-bucket.sgp1.digitaloceanspaces.com/selfpublishingtitans-booksbytitans-files/40cf61eb5e4647df883163b9fb42487f.png',
    id: '638cdb694c0e78001b31802c',
  },
  {
    title: 'Free KDP Interiors, Templates & More',
    link: '/blog/free-kdp-interiors-templates-and-more',
    date: '2022-11-30T16:21:55.804Z',
    thumbnail:
      'https://booksbytitans-bucket.sgp1.digitaloceanspaces.com/selfpublishingtitans-booksbytitans-files/3e4a6fe1798fe1e50bd33fc1781db7f6.jpg',
    id: '638781a64c0e78001b318028',
  },
  {
    title: 'KDP Code of Conduct & Ethics by Self Publishing Titans',
    link: '/blog/kdp-code-of-conduct-and-ethics-by-self-publishing-titans',
    date: '2022-09-17T14:38:42.342Z',
    thumbnail:
      'https://booksbytitans-bucket.sgp1.digitaloceanspaces.com/selfpublishingtitans-booksbytitans-files/76bae3c5dad5fa3762a870f1dc684ec4.png',
    id: '6325dbcf577c41001b770a78',
  },
  {
    title: 'KDP Niche Research Chrome Extension',
    link: '/blog/kdp-niche-research-chrome-extension',
    date: '2022-05-23T04:38:28.440Z',
    thumbnail:
      'https://booksbytitans-bucket.sgp1.digitaloceanspaces.com/selfpublishingtitans-booksbytitans-files/0dcde04251f044c217476a2696123178.png',
    id: '628b0fc0c9bd89001ad13c90',
  },
  {
    title: 'Huge Amazon Search Suggestion Expander',
    link: '/blog/huge-amazon-search-suggestion-expander',
    date: '2022-05-23T04:36:09.534Z',
    thumbnail:
      'https://booksbytitans-bucket.sgp1.digitaloceanspaces.com/selfpublishingtitans-booksbytitans-files/0c1bf3eab655a3e9e08280617b234289.png',
    id: '628b0f35c9bd89001ad13c8c',
  },
  {
    title:
      'KDP Niche Research 2021: How to Find HOT Niches. Keyword Research Strategy for Q4 & After. Free',
    link: '/blog/kdp-niche-research-2021-how-to-find-hot-niches-keyword-research-strategy-for-q4-and-after-free-1',
    date: '2022-03-06T08:01:43.550Z',
    thumbnail:
      'https://booksbytitans-bucket.sgp1.digitaloceanspaces.com/selfpublishingtitans-booksbytitans-files/9cfdc44fad23c53e835ed0b67253a5f5.jpg',
    id: '62243071501424001b05f413',
  },
  {
    title:
      'No.1 Amazon and KDP Keyword Search Suggestion Tool: Installation Walkthrough',
    link: '/blog/1-amazon-kdp-keyword-search-suggestion-tool-installation-walkthrough-1',
    date: '2022-03-06T03:53:20.132Z',
    thumbnail:
      'https://booksbytitans-bucket.sgp1.digitaloceanspaces.com/selfpublishingtitans-booksbytitans-files/e795627714018e80dcff9e5cfceac035.jpg',
    id: '62243027501424001b05f411',
  },
  {
    title:
      'KDP Niche Research Tool for Finding Profitable Low Content Keywords 2021 Installation Walkthrough',
    link: '/blog/kdp-niche-research-tool-for-finding-profitable-low-content-keywords-2021-installation-walkthrough',
    date: '2022-03-05T12:46:30.354Z',
    thumbnail:
      'https://booksbytitans-bucket.sgp1.digitaloceanspaces.com/selfpublishingtitans-booksbytitans-files/b742e416d5cd6b5382d6555186b77875.jpg',
    id: '62235b8593265e00a20dbf4f',
  },
  {
    title: 'Frequently Asked Questions, Things to Avoid and Tips for KDP',
    link: '/blog/frequently-asked-questions-things-to-avoid-and-tips-for-kdp',
    date: '2022-03-05T12:43:44.342Z',
    thumbnail:
      'https://booksbytitans-bucket.sgp1.digitaloceanspaces.com/selfpublishingtitans-booksbytitans-files/26adb7d518c7dea80c7930a050ded43b.jpeg',
    id: '62235a4793265e00a20dbf4c',
  },
];

const Index: React.FC = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchLoading, setSearchLoading] = useState({
    tools: false,
    community: false,
  });

  const [query, setQuery] = useState('');
  const [blogs, setBlogs] = useState<BlogPost[]>([]);

  const [toolResults, setToolResults] = useState<SectionItem[]>([]);
  const [communityResults, setCommunityResults] = useState<ApiResponse[]>([]);

  // eslint-disable-next-line consistent-return
  const filterData = (searchQuery: string) => {
    if (searchQuery === '') return setToolResults([]);

    const filteredResults = ToolSearchData.filter((item: SectionItem) =>
      item.item.toLowerCase().includes(query.toLowerCase()),
    ).slice(0, 4);

    setToolResults(filteredResults);
  };

  let abortController: AbortController | null = null;

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const simulateApiRequest = async (query: string) => {
    if (query === '') return;
    setCommunityResults([]);
    const params = {
      q: query,
      page: 1,
      order: 'relevance',
      size: 4,
    };

    if (abortController) {
      abortController.abort();
    }

    abortController = new AbortController();
    const { signal } = abortController;

    try {
      const response = await fetch(
        `https://community.selfpublishingtitans.com/answer/api/v1/search?q=${params.q}&page=${params.page}&order=${params.order}&size=${params.size}`,
        {
          method: 'GET',
          signal,
        },
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const CData = data.data.list as ApiResponse[];
      const results = CData;
      // eslint-disable-next-line consistent-return
      return results;
    } catch (error) {
      console.error(error);
      // eslint-disable-next-line consistent-return
      return [];
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const fetchCommunityResults = async (query: string) => {
    setSearchLoading((prev) => ({ ...prev, community: true }));
    const results = await simulateApiRequest(query);
    setCommunityResults(results || []);
    setSearchLoading((prev) => ({ ...prev, community: false }));
  };

  const debouncedFetchCommunityResults = useCallback(
    debounce(fetchCommunityResults, 300),
    [],
  );

  const filterBlogData = (searchQuery: string) => {
    const filteredResults = blogsdata
      .filter((item: BlogPost) =>
        item.title.toLowerCase().includes(searchQuery.toLowerCase()),
      )
      .slice(0, 4);

    setBlogs(filteredResults);
  };

  const handleInputChange = (event) => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const query = event.target.value;
    setQuery(query);
    setShowDropdown(true);
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    debouncedFilterData(query);
    debouncedFetchCommunityResults(query);
    filterBlogData(query);
  };

  const debouncedFilterData = useCallback(debounce(filterData, 0), []);

  function getCommunityLink(item: ApiResponse) {
    const baseUrl = 'https://community.selfpublishingtitans.com/questions/';
    if (item.object_type === 'question') {
      return baseUrl + item.object.id;
    }
    return `${baseUrl + item.object.question_id}/${item.object.id}`;
  }

  return (
    <div
      style={{
        position: 'relative',
        width: '250px',
        fontFamily: 'sans-serif',
      }}>
      <div style={{ position: 'relative', marginRight: '0.5rem' }}>
        <input
          style={{
            border: '1px solid #ccc',
            borderRadius: '30px',
            outline: 'none',
            padding: '0.625rem 0.75rem',
            width: '100%',
            paddingLeft: '2.5rem',
          }}
          type="text"
          id="outlined-basic"
          placeholder="Search"
          onChange={handleInputChange}
          onFocus={() => setShowDropdown(true)}
          autoComplete="off"
        />
        <MdSearch
          style={{
            position: 'absolute',
            left: '0.75rem',
            top: '50%',
            transform: 'translateY(-50%)',
            color: '#9ca3af',
            fontSize: '20px',
          }}
        />
      </div>
      {showDropdown && query && (
        <div
          style={{
            position: 'absolute',
            left: '0',
            right: '0',
            marginTop: '8px',
            backgroundColor: 'white',
            color: 'black',
            border: '1px solid #ccc',
            width: '350px',
            borderRadius: '8px',
            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
            zIndex: 10,
          }}>
          <div style={{ padding: '8px' }}>
            {searchLoading.tools ? (
              <div className="d-flex justify-content-center">
                <Spinner animation="border" role="status" size="sm">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <div className="d-flex align-items-center pt-2 px-2">
                <div
                  className="fw-bold d-flex gap-2 align-items-center"
                  style={{ fontSize: '16px' }}>
                  <i className="bi bi-tools" style={{ fontSize: '18px' }} />
                  Tools
                </div>
              </div>
            )}
            <div className="px-2">
              <hr
                style={{
                  margin: '0',
                  borderTop: '1px solid #e2e8f0',
                  borderBottom: '1px solid #edf2f7',
                }}
              />
            </div>
            <ListGroup variant="flush">
              {toolResults.map((item, index) => (
                <ListGroup.Item
                  action
                  href={item.productLink}
                  target="__blank"
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  className="d-flex flex-column"
                  style={{
                    borderBottom: '1px solid #ccc',
                    cursor: 'pointer',
                    background: 'white',
                    color: '#000',
                  }}>
                  <div
                    className=" "
                    style={{
                      fontSize: '14.2px',
                    }}>
                    {item.item}
                  </div>
                  <div
                    className=""
                    style={{
                      fontSize: '12px',
                      color: '#696969',
                    }}>
                    {item.tag}
                  </div>
                </ListGroup.Item>
              ))}
              {toolResults.length === 0 && query && (
                <div className="p-2 text-muted">No results found</div>
              )}
            </ListGroup>
          </div>
          <div className="p-2">
            <div className="d-flex align-items-center px-2">
              <div
                className="fw-bold d-flex gap-2 align-items-center"
                style={{ fontSize: '16px' }}>
                <i className="bi bi-chat-dots" style={{ fontSize: '18px' }} />
                Community
              </div>
            </div>
            <div className="px-2 ">
              <hr
                style={{
                  margin: '0',
                  borderTop: '1px solid #e2e8f0',
                  borderBottom: '1px solid #edf2f7',
                }}
              />
            </div>
            <ListGroup variant="flush">
              {communityResults.map((item, index) => (
                <ListGroup.Item
                  action
                  href={getCommunityLink(item)}
                  target="__blank"
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  className="d-flex flex-column"
                  style={{
                    borderBottom: '1px solid #ccc',
                    cursor: 'pointer',
                    background: 'white',
                  }}>
                  <div
                    style={{
                      fontSize: '14.2px',
                    }}
                    className=" fs-6 truncate-text text-dark">
                    {item.object_type === 'question'
                      ? item.object.title.slice(20)
                      : item.object.excerpt.slice(20)}
                  </div>
                  <div className="d-flex gap-2 pt-1">
                    <div
                      className=""
                      style={{
                        color: '#696969',
                        fontSize: '12px',
                      }}>
                      Answers: {item.object.vote_count}
                    </div>
                    <div
                      className=""
                      style={{
                        color: '#696969',
                        fontSize: '12px',
                      }}>
                      Votes: {item.object.answer_count}
                    </div>
                  </div>
                </ListGroup.Item>
              ))}
              {communityResults.length === 0 &&
                query &&
                !searchLoading.community && (
                  <div className="p-2 text-muted">No results found</div>
                )}
              {searchLoading.community && <div className="p-2">loading...</div>}
            </ListGroup>
          </div>
          <div className="p-2">
            <div className="d-flex align-items-center px-2">
              <div
                className="fw-bold d-flex gap-2 align-items-center"
                style={{ fontSize: '16px' }}>
                <i
                  className="bi bi-file-earmark-text"
                  style={{ fontSize: '18px' }}
                />
                Blog
              </div>
            </div>
            <div className="px-2 mt-2">
              <hr
                style={{
                  margin: '0',
                  borderTop: '1px solid #e2e8f0',
                  borderBottom: '1px solid #edf2f7',
                }}
              />
            </div>
            <ListGroup variant="flush">
              {blogs.map((item, index) => (
                <ListGroup.Item
                  action
                  href={`https://www.selfpublishingtitans.com${item.link}`}
                  target="__blank"
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  className="d-flex flex-column"
                  style={{
                    borderBottom: '1px solid #ccc',
                    cursor: 'pointer',
                    background: 'white',
                  }}>
                  <div
                    className=" text-dark"
                    style={{
                      fontSize: '14.2px',
                    }}>
                    {item.title}
                  </div>
                </ListGroup.Item>
              ))}
              {blogs.length === 0 && query && (
                <div className="p-2 text-muted">No results found</div>
              )}
            </ListGroup>
          </div>
        </div>
      )}
    </div>
  );
};

export default Index;

type BlogPost = {
  title: string;
  link: string;
  date: string;
  thumbnail: string;
  id: string;
};

interface UserInfo {
  id: string;
  username: string;
  display_name: string;
  rank: number;
  status: string;
}

interface Tag {
  slug_name: string;
  display_name: string;
  main_tag_slug_name: string;
  recommend: boolean;
  reserved: boolean;
}

interface AnswerObject {
  id: string;
  question_id: string;
  title: string;
  url_title: string;
  excerpt: string;
  created_at: number;
  vote_count: number;
  accepted: boolean;
  answer_count: number;
  user_info: UserInfo;
  tags: Tag[];
  status: string;
}

export interface ApiResponse {
  object_type: string;
  object: AnswerObject;
}
