import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { ChevronRight, ChevronDown } from 'lucide-react';
import ListItem from './ListItem';

import SolutionsData, {
  TCategory,
  TResourcesData,
  Item,
} from './ResourcesData';

function ResourcesMenu() {
  const handleCategoryClick = (category: TResourcesData) => {
    setSelectedCategory(category);
  };

  const [selectedCategory, setSelectedCategory] = useState<TResourcesData>(
    SolutionsData[0],
  );

  const flattenedItems = selectedCategory.Categories.flatMap(
    (category) => category.Items,
  );

  return (
    <div
      style={{ height: 'fit-content', borderRadius: '16px', width: '800px' }}>
      <div
        style={{
          // width: '100%',
          display: 'flex',
          flexDirection: 'row',
          maxHeight: '88vh',
          overflowY: 'auto',
        }}>
        <div
          style={{
            display: 'flex',
            borderRadius: '16px',
            padding: '12px',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
          }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              // paddingLeft: '16px',
              transition: 'height 0.3s',
            }}>
            {selectedCategory ? (
              // @ts-ignore
              <div key={selectedCategory.Title} className="category-content">
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: `repeat(2, minmax(0, 1fr))`,
                    gap: '8px',
                  }}>
                  {flattenedItems.map((item) => (
                    <ListItem
                      key={item.heading}
                      title={item.heading}
                      newTab={item.newTab}
                      Icon={item.icon}>
                      {item.tag}
                    </ListItem>
                  ))}
                </div>
              </div>
            ) : (
              <span>Select a category to view details</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

interface SolutionsDropDownProps {
  show: boolean;
}

function SolutionsDropDown({ show }: SolutionsDropDownProps) {
  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <button
        style={{
          background: 'transparent',
          border: 'none',
          display: 'flex',
          alignItems: 'center',
          gap: '4px',
          cursor: 'pointer',
          color: 'black',
        }}>
        <span>Resources</span>{' '}
        <ChevronDown
          className=""
          style={{
            width: '14px',
          }}
        />
      </button>
      {show && (
        <div
          style={{
            position: 'absolute',
            top: '100%',
            left: '0',
            backgroundColor: 'white',
            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
            borderRadius: '16px',
            zIndex: 1,
          }}>
          <ResourcesMenu />
        </div>
      )}
    </div>
  );
}

export default SolutionsDropDown;
