import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { ArrowRight, ArrowDown, ChevronDown, ChevronRight } from 'lucide-react';

import SolutionsData, {
  TCategory,
  TSolutionsData,
  Item,
} from './solutionsData';
import { FaChevronRight } from 'react-icons/fa';
import ListItem from './ListItem';
import { domains } from '../constants';

function SolutionsComp() {
  const [selectedCategory, setSelectedCategory] = useState<TSolutionsData>(
    SolutionsData[0],
  );

  const handleCategoryClick = (category: TSolutionsData) => {
    setSelectedCategory(category);
  };

  const flattenedItems = selectedCategory.Categories.flatMap(
    (category) => category.Items,
  );

  return (
    <div style={{ height: 'fit-content', borderRadius: '16px', width: '75vw' }}>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          maxHeight: '88vh',
          overflowY: 'auto',
        }}>
        <div
          style={{
            display: 'flex',
            borderRadius: '16px',
            padding: '12px',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
          }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '250px',
            }}>
            {SolutionsData.map((category: TSolutionsData) => (
              <div
                key={category.Title as string}
                style={{
                  display: 'flex',
                  borderRadius: '12px',
                  padding: '18px 14px',
                  margin: '4px 0',
                  width: '240px',
                  justifyContent: 'space-between',
                  color:
                    selectedCategory.Title === category.Title
                      ? 'white'
                      : 'black',

                  cursor: 'pointer',
                  // background: '#f7f6f8',
                  backgroundColor:
                    selectedCategory.Title === category.Title
                      ? '#7936ec'
                      : '#f7f6f8',
                }}
                onMouseEnter={() => handleCategoryClick(category)}>
                <div
                  style={{
                    display: 'flex',
                    gap: '8px',
                    alignItems: 'center',
                    // justifyContent: 'center',
                  }}
                  className="">
                  <div
                    style={{
                      padding: '4px',
                      width: '30px',
                      height: '30px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: '50%',
                      color:
                        selectedCategory.Title === category.Title
                          ? '#7936ec'
                          : 'black',
                      backgroundColor:
                        selectedCategory.Title === category.Title
                          ? '#fff'
                          : '#fff',
                    }}
                    className="">
                    <category.icon
                      style={{
                        width: '14px',
                        height: '14px',
                      }}
                    />
                  </div>
                  <span>{category.Title}</span>
                </div>
                <ChevronRight
                  style={{
                    width: '16px',
                  }}
                />
              </div>
            ))}
            {/* <Link
              to="/services"
              style={{
                display: 'flex',
                borderRadius: '12px',
                padding: '8px',
                margin: '4px 0',
                justifyContent: 'space-between',
                color: 'black',
                cursor: 'pointer',
                backgroundColor: 'white',
              }}>
              <span>Services</span>
              <ChevronRight />
            </Link> */}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              paddingLeft: '16px',
              transition: 'height 0.3s',
            }}>
            {selectedCategory ? (
              // @ts-ignore
              <div key={selectedCategory.Title} className="category-content">
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: `repeat(2, minmax(0, 1fr))`,
                    gap: '8px',
                  }}>
                  {/* {selectedCategory.Categories.map((category: TCategory) => (
                    <div
                      key={category.Heading as string}
                      style={{ paddingLeft: '8px' }}>
                      <span style={{ color: 'black', paddingLeft: '8px' }}>
                        {category.Heading}
                      </span>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          marginTop: '12px',
                        }}> */}
                  {flattenedItems.map((item: Item) => (
                    // <Link
                    //   to={item.link}
                    //   target={item.newTab ? '_blank' : '_self'}
                    //   key={item.link}
                    //   style={{ textDecoration: 'none' }}>
                    //   <div
                    //     style={{
                    //       backgroundColor: 'white',
                    //       color: 'black',
                    //       borderRadius: '8px',
                    //       padding: '8px',
                    //       marginBottom: '8px',
                    //       cursor: 'pointer',
                    //       transition: 'background-color 0.3s',
                    //     }}
                    //     onMouseEnter={(e) => {
                    //       e.currentTarget.style.backgroundColor = '#c3abff';
                    //     }}
                    //     onMouseLeave={(e) => {
                    //       e.currentTarget.style.backgroundColor = 'white';
                    //     }}>
                    //     <div
                    //       style={{
                    //         fontWeight: 'bold',
                    //         color: 'black',
                    //       }}>
                    //       {item.heading}
                    //     </div>
                    //     <div style={{ color: 'black' }}>{item.tag}</div>
                    //   </div>
                    // </Link>

                    <ListItem
                      key={item.link}
                      title={item.heading}
                      href={item.link}
                      Icon={item.icon}>
                      {' '}
                      {item.tag}
                    </ListItem>
                  ))}
                  <Link
                    to={domains.main + '/tools'}
                    target={true ? '_blank' : '_self'}
                    style={{
                      display: 'block',
                      textDecoration: 'none',
                      // border: '1px solid #e6e6e6',
                      borderRadius: '8px',
                      padding: '12px',
                      backgroundColor: '#f7f6f8',
                      transition: 'all 0.2s ease-in-out',
                    }}
                    onMouseEnter={(e) =>
                      (e.currentTarget.style.backgroundColor = '#e6e6fa')
                    }
                    onMouseLeave={(e) =>
                      (e.currentTarget.style.backgroundColor = '#f7f6f8')
                    }>
                    <div className="d-flex gap-3">
                      <div>
                        <div
                          style={{
                            // fontSize: '1rem',
                            // fontWeight: '500',
                            color: '#000',
                            // lineHeight: '1.2',
                          }}>
                          See & Compare All Tools
                        </div>
                        <p
                          style={{
                            fontSize: '0.875rem',
                            marginTop: '4px',
                            color: '#6c757d',
                          }}>
                          See all we provide to find what you need.
                        </p>
                      </div>
                      <ArrowRight className="align-self-center text-primary" />
                    </div>
                  </Link>
                  {/* </div>
                    </div>
                  ))} */}
                </div>
              </div>
            ) : (
              <span>Select a category to view details</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
interface SolutionsDropDownProps {
  show: boolean;
}

function SolutionsDropDown({ show }: SolutionsDropDownProps) {
  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <button
        style={{
          background: 'transparent',
          border: 'none',
          display: 'flex',
          alignItems: 'center',
          gap: '4px',
          cursor: 'pointer',
          color: 'black',
        }}>
        <span>Tools</span>{' '}
        <ChevronDown
          className=""
          style={{
            width: '14px',
          }}
        />
      </button>
      {show && (
        <div
          style={{
            position: 'absolute',
            top: '100%',
            left: '0',
            backgroundColor: 'white',
            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
            borderRadius: '16px',
            zIndex: 1,
          }}>
          <SolutionsComp />
        </div>
      )}
    </div>
  );
}

export default SolutionsDropDown;
