import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { formatDistanceToNow } from 'date-fns';
import { Badge } from 'react-bootstrap';

import { EDropdown } from './index';
import { MdRefresh, MdNotifications, MdDelete } from 'react-icons/md';
import { Card } from 'react-bootstrap';
import { BellIcon } from 'lucide-react';

type TUser = {
  verified: boolean;
  type: number;
  source: string;
  is_blocked: boolean;
  _id: string;
  email: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
  id: string;
  has_past_due: boolean;
};

interface INotification {
  id: string;
  user_id: string;
  title: string;
  message: string;
  type: string;
  category: string;
  icon: string;
  link: string;
  is_read: boolean;
  is_deleted: boolean;
  created_at: string;
  notification_id: number;
}

interface INotificationsResponse {
  notifications: INotification[];
  current_page: number;
  has_more: boolean;
  next_page: number;
  total_count: number;
}

interface INotificationProps {
  info: TUser;
  show: boolean;
  toggleDropDown: (type: EDropdown) => void;
  isMobile?: boolean;
  token?: string;
  transparentNav?: boolean;
}

const Notifications: React.FC<INotificationProps> = ({
  info,
  show,
  toggleDropDown,
  isMobile,
  token = '',
  transparentNav,
}) => {
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
    toggleDropDown(EDropdown.notification);
  };

  const handleClose = () => {
    setIsOpen(false);
    toggleDropDown(EDropdown.notification);
  };

  const base = 'https://stingray-app-tx5jj.ondigitalocean.app';

  const getNotifications = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        base +
          `/api/notifications?page=${page}&page_size=5&user_id=${info._id}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      if (!response.ok) {
        throw new Error('Failed to fetch notifications');
      }

      const data: INotificationsResponse = await response.json();

      setHasMore(data.has_more);
      // @ts-ignore
      setNotifications((prevNotifications) => {
        try {
          const updatedNotifications =
            page === 1
              ? data.notifications
              : [...prevNotifications, ...data.notifications];

          // Sort notifications by created_at in descending order
          const sortedNotifications = updatedNotifications.sort(
            (a, b) =>
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime(),
          );

          // Update the notification count
          setNotificationCount(sortedNotifications.length);

          return sortedNotifications;
        } catch (error) {
          console.error('Error updating notifications:', error);
          return prevNotifications; // Return the previous state in case of an error
        }
      });
    } catch (error) {
      console.error('Error fetching notifications:', error);
    } finally {
      setLoading(false);
    }
  };

  //  useEffect(() => {
  //    const interval = setInterval(() => {
  //      getNotifications();
  //    }, 60000); // Check for new notifications every 60 seconds

  //    return () => clearInterval(interval);
  //  }, []);

  useEffect(() => {
    getNotifications();
  }, [page]);

  const DeleteNotification = async (id: number) => {
    try {
      const response = await fetch(
        base + `/api/notifications?notification_id=${id}&user_id=${info._id}`,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (!response.ok) {
        throw new Error('Failed to delete notification');
      }

      setNotifications((prevNotifications) =>
        prevNotifications.filter(
          (notification) => notification.notification_id !== id,
        ),
      );
    } catch (error) {
      console.error('Error deleting notification:', error);
    }
  };

  async function MarkAsRead(id: number) {
    try {
      const response = await fetch(
        base +
          `/api/notifications/mark-as-read?notification_id=${id}&user_id=${info._id}`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (!response.ok) {
        throw new Error('Failed to mark notification as read');
      }
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  }

  function HandleLoadMore() {
    setPage(page + 1);
  }

  function Refresh() {
    setNotifications([]);
    setHasMore(true);
    setPage(1);
    getNotifications();
  }
  return (
    <div
      style={{
        position: 'relative',
        marginRight: '0.8rem',
        display: 'inline-block',
        textAlign: 'left',
      }}>
      <div
        onClick={handleClick}
        onMouseEnter={handleClick}
        style={{
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '40px',
          height: '40px',
          // border: '1px solid',
          borderColor: transparentNav ? 'white' : '#7449fb',
          borderRadius: '50%',
          backgroundColor: 'white',
          cursor: 'pointer',
          position: 'relative',
        }}>
        <div style={{ display: 'inline-block' }}>
          <BellIcon style={{ color: 'black', fontSize: '22px' }} />
          {notificationCount > 0 && (
            <Badge
              pill
              bg="primary"
              style={{
                position: 'absolute',
                top: '0',
                right: '0',
                transform: 'translate(50%, -50%)',
              }}>
              {notificationCount}
              <span className="visually-hidden">unread messages</span>
            </Badge>
          )}
        </div>
      </div>

      {show && (
        <div
          style={{
            border: '1px solid #ccc',
            left: '50%',
            transform: isMobile ? 'translateX(-50%)' : 'translateX(-70%)',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Example box shadow
            maxHeight: '250px',
            overflowY: 'auto',
            position: 'absolute',
            padding: '0.5rem',
            marginTop: '0.5rem',
            width: '350px',
            borderRadius: '0.5rem',
            backgroundColor: 'white',
            zIndex: 10,
          }}
          onMouseLeave={handleClose}>
          <div>
            <span
              style={{
                paddingLeft: '0.5rem',
                paddingRight: '0.5rem',
                color: 'black',
                fontWeight: 'bold',
                marginBottom: '0.5rem',
              }}>
              Notifications ({notificationCount})
            </span>
            <MdRefresh
              onClick={Refresh}
              style={{
                color: 'black',
                cursor: 'pointer',
                position: 'absolute',
                right: '0.5rem',
                top: '0.5rem',
              }}
            />
          </div>
          {notifications.length > 0 ? (
            notifications.map((notification, index) => (
              // @ts-ignore
              <NotificationCard
                key={index}
                index={index}
                Notification={notification}
                user_id={info._id}
                markAsRead={MarkAsRead}
                deleteNotification={DeleteNotification}
              />
            ))
          ) : (
            <span
              style={{
                padding: '0.5rem',
              }}>
              No notifications
            </span>
          )}
          {hasMore && (
            <div
              onClick={HandleLoadMore}
              style={{
                textAlign: 'center',
                color: 'blue',
                cursor: 'pointer',
                fontWeight: 'bold',
                fontFamily: 'sans-serif',
              }}>
              {loading ? 'Loading...' : 'Load More'}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Notifications;

interface INotificationProps {
  Notification: INotification;
  index: number;
  user_id: string;
  deleteNotification: (id: number) => void;
  markAsRead: (id: number) => void;
}

export const NotificationCard: React.FC<INotificationProps> = ({
  Notification,
  index,
  user_id,
  deleteNotification,
  markAsRead,
}) => {
  const notificationDate = new Date(Notification.created_at);
  const displayDate = isNaN(notificationDate.getTime())
    ? new Date()
    : notificationDate;

  async function handleClick() {
    markAsRead(Notification.notification_id);
    window.open(Notification.link, '_blank');
  }

  const [isHovered, setIsHovered] = useState(false);

  return (
    <Card
      style={{
        borderTop: index !== 0 ? '1px solid #ccc' : '1px solid #ccc',
        backgroundColor: isHovered
          ? '#e0e0e0'
          : Notification.is_read
            ? '#e0e0e0'
            : 'white',
        cursor: 'pointer',
        marginTop: '0.5rem',
        transition: 'background-color 0.2s',
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className="p-2 rounded-lg"
      onClick={handleClick}>
      <Card.Body className="d-flex justify-content-between align-items-center">
        <div className="d-flex flex-column">
          <Card.Title
            style={{
              fontSize: '16px',
              marginBottom: '0',
            }}
            className="font-weight-bold text-dark">
            {Notification.title}
          </Card.Title>
          <Card.Text
            style={{
              marginBottom: '0',
            }}
            className="text-muted">
            {Notification.message}
          </Card.Text>
          <Card.Text
            style={{
              fontSize: '12px',
              marginTop: '5px',
            }}
            className="text-secondary">
            {formatDistanceToNow(displayDate)} ago
          </Card.Text>
        </div>
        <div className="d-flex justify-content-end mt-2">
          <MdDelete
            className="text-dark"
            onClick={(e) => {
              e.stopPropagation();
              deleteNotification(Notification.notification_id);
            }}
          />
        </div>
      </Card.Body>
    </Card>
  );
};
