/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

import React, { FC } from 'react';
import { Col, Nav } from 'react-bootstrap';
import { NavLink, useLocation, useMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import SolutionsData from '../Header/solutionsData';
import ResourcesData from '../Header/ResourcesData';
import { Link } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import { MdKeyboardArrowDown } from 'react-icons/md';

import classnames from 'classnames';

import { loggedUserInfoStore, sideNavStore } from '@/stores';
import { Icon } from '@/components';
import './index.scss';

const mainSite = 'https://selfpublishingtitans.com';

const Index: FC = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { user: userInfo } = loggedUserInfoStore();
  const { visible, can_revision, revision } = sideNavStore();

  const [isShowDropDown, setIsShowDropDown] = useState([false, false, false]);

  const tagMatch = useMatch('/tags/:slugName');
  let askUrl = '/questions/ask';
  if (tagMatch && tagMatch.params.slugName) {
    askUrl = `${askUrl}?tags=${encodeURIComponent(tagMatch.params.slugName)}`;
  }

  const [isShowChildDropDown, setIsShowChildDropDown] = useState({});
  const toggleChildDropdown = (index) => {
    setIsShowChildDropDown((prevState) => {
      const newState = Object.keys(prevState).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {});
      newState[index] = !prevState[index];
      return newState;
    });
  };

  function NavLink2({ href, name, refresh, target }) {
    return refresh ? (
      <Link to={href} target={target} rel="noreferrer">
        <span className="cursor-pointer block py-2 text-sm pr-4 pl-3 text-gray-700 border-b border-secCol1-50 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-secCol1">
          {name}
        </span>
      </Link>
    ) : (
      <li className="cursor-pointer block py-2 text-xs pr-4 pl-3 text-gray-700 border-b border-secCol1-50 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-secCol1-700 ">
        <Link to={href}>
          <span className="text-sm">{name}</span>
        </Link>
      </li>
    );
  }

  return (
    <Col
      xl={2}
      lg={3}
      md={12}
      className={classnames(
        'position-relative',
        visible ? '' : 'd-none d-lg-block',
      )}
      id="sideNav">
      {/* <div className="side-nav-right-line" /> */}
      <div
        onClick={() => setIsShowDropDown([!isShowDropDown[0], false, false])}
        className={`d-flex px-3 d-flex d-lg-none mt-4 justify-content-between w-100 `}>
        Tools
        <MdKeyboardArrowDown className="text-secondary md-text-white d-inline-block my-auto ms-1 me-4" />
      </div>
      <div className="nav-wrap pt-3">
        <div
          className={`animate__animated animate__fadeIn animate__faster ${
            isShowDropDown[0] ? '' : 'd-none'
          } position-relative lg-position-absolute left-0 right-0 top-10 z-10 w-100 lg-w-25 text-base list-unstyled rounded bg-white border border-secondary lg-shadow`}>
          <ul className="py-1 ps-3 lg-ps-0 rounded">
            {SolutionsData.map((dropdownItem, dii) => {
              return (
                <React.Fragment key={dii}>
                  <div
                    className="d-flex justify-content-between"
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleChildDropdown(dii);
                    }}>
                    <p className="text-sm my-1 py-1">{dropdownItem.Title}</p>
                    {/* <svg
                      className="w-5 h-4 text-secondary md-text-white d-inline-block ms-1"
                      viewBox="0 0 20 20"
                      fill="gray">
                      <path
                        fillRule="evenodd"
                        d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                        clipRule="evenodd"></path>
                    </svg> */}
                    <MdKeyboardArrowDown className="text-secondary md-text-white d-inline-block my-auto ms-1 me-4" />
                  </div>
                  <Collapse in={isShowChildDropDown[dii]}>
                    <div
                      className={`animate__animated animate__fadeIn animate__faster ${
                        isShowChildDropDown[dii] ? '' : 'd-none'
                      } position-relative lg-position-absolute left-0 right-0 top-10 z-10 w-100 lg-w-25 text-base list-unstyled rounded bg-white border border-secondary lg-shadow`}>
                      <ul className="py-1 ps-3 lg-ps-0 rounded">
                        {dropdownItem.Categories.map((dropdownItem2, diii) => {
                          return (
                            <div key={diii}>
                              <p className="text-sm font-weight-bold my-1 py-1">
                                {dropdownItem2.Heading}
                              </p>
                              {dropdownItem2.Items.map((item, diiii) => {
                                return (
                                  <div
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                    key={diiii}>
                                    <NavLink2
                                      key={diiii}
                                      name={item.heading}
                                      href={''}
                                      refresh={false}
                                      target={'_blank'}
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          );
                        })}
                      </ul>
                    </div>
                  </Collapse>
                </React.Fragment>
              );
            })}
          </ul>
        </div>
        <div
          onClick={() => setIsShowDropDown([false, !isShowDropDown[1], false])}
          className={`d-flex px-3 pb-3 d-lg-none  justify-content-between w-100 `}>
          Resources
          <MdKeyboardArrowDown className="text-secondary md-text-white d-inline-block my-auto ms-1 me-4" />
        </div>
        <div
          className={`animate__animated animate__fadeIn animate__faster ${
            isShowDropDown[1] ? '' : 'd-none'
          } position-relative lg-position-absolute left-0 right-0 top-10 z-10 w-100 lg-w-25 text-base list-unstyled rounded bg-white border border-secondary lg-shadow`}>
          <ul className="py-1 ps-3 lg-ps-0 rounded">
            {ResourcesData.map((dropdownItem, dii) => {
              return (
                <React.Fragment key={dii}>
                  <div
                    className="d-flex justify-content-between"
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleChildDropdown(dii);
                    }}>
                    <p className="text-sm my-1 py-1">{dropdownItem.Title}</p>
                    {/* <svg
                      className="w-5 h-4 text-secondary md-text-white d-inline-block ms-1"
                      viewBox="0 0 20 20"
                      fill="gray">
                      <path
                        fillRule="evenodd"
                        d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                        clipRule="evenodd"></path>
                    </svg> */}
                    <MdKeyboardArrowDown className="text-secondary md-text-white d-inline-block my-auto ms-1 me-4" />
                  </div>
                  <Collapse in={isShowChildDropDown[dii]}>
                    <div
                      className={`animate__animated animate__fadeIn animate__faster ${
                        isShowChildDropDown[dii] ? '' : 'd-none'
                      } position-relative lg-position-absolute left-0 right-0 top-10 z-10 w-100 lg-w-25 text-base list-unstyled rounded bg-white border border-secondary lg-shadow`}>
                      <ul className="py-1 ps-3 lg-ps-0 rounded">
                        {dropdownItem.Categories.map((dropdownItem2, diii) => {
                          return (
                            <div key={diii}>
                              <p className="text-sm font-weight-bold my-1 py-1">
                                {dropdownItem2.Heading}
                              </p>
                              {dropdownItem2.Items.map((item, diiii) => {
                                return (
                                  <div
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                    key={diiii}>
                                    <NavLink2
                                      key={diiii}
                                      name={item.heading}
                                      href={''}
                                      refresh={false}
                                      target={'_blank'}
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          );
                        })}
                      </ul>
                    </div>
                  </Collapse>
                </React.Fragment>
              );
            })}
          </ul>
        </div>
        <Nav variant="pills" className="flex-column">
          <NavLink
            to={mainSite + '/pricing'}
            target="_blank"
            className="d-flex nav-link d-lg-none">
            {/* <Icon name="question-circle-fill" className="me-2" /> */}
            <span>Pricing</span>
          </NavLink>
          <NavLink
            target="_blank"
            to={mainSite + '/testimonials'}
            className="d-flex nav-link d-lg-none">
            {/* <Icon name="question-circle-fill" className="me-2" /> */}
            <span>Testimonials</span>
          </NavLink>
          {userInfo?.username && (
            <NavLink
              to={askUrl}
              className={({ isActive }) =>
                isActive || pathname === '/questions/ask'
                  ? 'nav-link active'
                  : 'nav-link'
              }>
              <Icon name="plus-circle-fill" className="me-2" />
              <span>{t('btns.add_question')} </span>
            </NavLink>
          )}
          <NavLink
            to="/questions"
            className={({ isActive }) =>
              pathname === '/questions' ? 'nav-link active' : 'nav-link'
            }>
            <Icon name="question-circle-fill" className="me-2" />
            <span>{t('header.nav.question')}</span>
          </NavLink>
          <NavLink
            to="/tags"
            className={() =>
              pathname === '/tags' ? 'nav-link active' : 'nav-link'
            }>
            <Icon name="tags-fill" className="me-2" />
            <span>{t('header.nav.tag')}</span>
          </NavLink>
          {/* <NavLink to="/users" className="nav-link">
            <Icon name="people-fill" className="me-2" />
            <span>{t('header.nav.user')}</span>
          </NavLink> */}
          {/* <NavLink to="/badges" className="nav-link">
            <Icon name="award-fill" className="me-2" />
            <span>{t('header.nav.badges')}</span>
          </NavLink> */}
          {can_revision || userInfo?.role_id === 2 ? (
            <>
              <div className="py-2 px-3 mt-3 small fw-bold">
                {t('header.nav.moderation')}
              </div>
              {can_revision && (
                <NavLink to="/review" className="nav-link">
                  <span>{t('header.nav.review')}</span>
                  <span className="float-end">
                    {revision > 99 ? '99+' : revision > 0 ? revision : ''}
                  </span>
                </NavLink>
              )}

              {userInfo?.role_id === 2 ? (
                <NavLink to="/admin" className="nav-link">
                  {t('header.nav.admin')}
                </NavLink>
              ) : null}
            </>
          ) : null}
        </Nav>
      </div>
      <div className="side-nav-right-line" />
    </Col>
  );
};

export default Index;
