import React, { useState, ElementType } from 'react';
import { Link } from 'react-router-dom';

interface ListItemProps extends React.ComponentPropsWithoutRef<'a'> {
  title: string;
  Icon?: ElementType<any>;
  newTab?: boolean;
}

const ListItem = React.forwardRef<React.ElementRef<'a'>, ListItemProps>(
  ({ className, title, target, children, Icon, newTab, ...props }, ref) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
      <Link
        to={props.href || ''}
        ref={ref}
        style={{
          background: isHovered ? '#e6e6fa' : '#f7f6f8',
          borderRadius: '12px',
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        target={newTab ? '_blank' : '_self'}
        className={`d-flex align-items-start gap-3 p-3 text-decoration-none text-reset ${className}`}
        {...props}>
        {Icon && (
          <div>
            <Icon
              style={{
                width: '20px',
                strokeWidth: '2',
              }}
              className="text-primary"
            />
          </div>
        )}
        <div>
          <div className="mb-1">{title}</div>
          <p className="small text-muted mb-0 text-truncate">{children}</p>
        </div>
      </Link>
    );
  },
);

ListItem.displayName = 'ListItem';

export default ListItem;
